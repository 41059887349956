import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/use-auth.js";
import Navbar from "../components/Navbar.js";

function RequireAuth({ children }) {
  let auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.loadCurrentUser();
  }, []);

  useEffect(() => {
    if (!auth.user && auth.initialCurrentUserLoad && !auth.loading) {
      navigate("/login?tokenTimeout=true");
    }
  }, [auth.user, auth.initialCurrentUserLoad, auth.loading]);

  return (
    <div id="require-auth-layout">
      <Navbar />
      {children}
    </div>
  );
}

export default RequireAuth;

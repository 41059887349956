import { CaretDown, CaretUp, Trash } from "phosphor-react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/common/TableItems";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { useRemoveBookmarkSolution } from "src/hooks/sessions";
import { getCustomTableColor, getErrorMsg } from "src/utils/Utils";
import { defaultMultiTreeTableHeaders } from "src/utils/types";
import { Fragment, useMemo, useState } from "react";
import ComparisonTableModelSubTreeTable from "../multitree/filter/ComparisonTableModelSubTree";

const MultiTreeBookmarkListTable = ({
  existingBookmarks
}) => {
  const { notify } = useNotifier();
  const theme = useTheme();
  const removeBookmarkSolution = useRemoveBookmarkSolution();
  const [closedRows, setClosedRows] = useState([]);

  const otherFields = useMemo(() => {
    if (!existingBookmarks || existingBookmarks.length === 0) {
      return [];
    }

    let subTreeOtherFields = new Set();
    let modelOtherFields = new Set();

    existingBookmarks.forEach((item) => {
      Object.keys(item.other || {}).forEach((key) => {
        if (!modelOtherFields.has(key)) {
          modelOtherFields.add(key);
        }
      });

      (item.trees || []).forEach((tree) => {
        Object.keys(tree).forEach((key) => {
          if (
            !subTreeOtherFields.has(key) &&
            !defaultMultiTreeTableHeaders.has(key)
          ) {
            subTreeOtherFields.add(key);
          }
        });
      });
    });
    return {
      model: Array.from(modelOtherFields),
      tree: Array.from(subTreeOtherFields),
    };
  }, [existingBookmarks]);

  const removeBookmark = async (solution) => {
    try {
      await removeBookmarkSolution.mutateAsync({
        resultId: solution.resultId,
        modelId: solution.id,
        sessionId: solution.session,
        algorithm: solution.algorithm,
      });
    } catch (error) {
      notify(NotificationType.ERROR, getErrorMsg(error));
    }
  };

  const openDetails = (index) => {
    const filtered = closedRows.filter((i) => i !== index);
    setClosedRows(filtered);
  };

  const closeDetails = (index) => {
    setClosedRows((prev) => [...prev, index]);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="left">
                
            </StyledTableCell>
            {otherFields.model.map((field) => (
              <StyledTableCell
                align="left"
                key={field}
              >
                {field}
              </StyledTableCell>
            ))}
            <StyledTableCell align="right" />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {existingBookmarks.map((item, index) => {
            const rowBackgroundColor = getCustomTableColor(
              Number(index)
            );
            const isExpanded = !closedRows.some((i) => i == item.id);
            return (
              <Fragment>
                <StyledTableRow key={item.id}>
                  <StyledTableCell align="left" width={'80px'}>
                    <IconButton
                      sx={{
                        width: "2rem",
                        height: "2rem",
                        padding: ".25rem",
                      }}
                      onClick={() =>
                        !isExpanded
                          ? openDetails(item.id)
                          : closeDetails(item.id)
                      }
                    >
                      {" "}
                      {isExpanded ? (
                        <CaretUp size={18} />
                      ) : (
                        <CaretDown size={18} />
                      )}
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      {item.id}
                    </Typography>
                  </StyledTableCell>
                  {otherFields.model.map((field) => (
                    <StyledTableCell align="left">
                      {"other" in item ? item.other[field] || "-" : "-"}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="right">
                    <IconButton
                      sx={{ width: "32px", height: "32px" }}
                      onClick={() => removeBookmark(item)}
                    >
                      <Trash size={20} color={theme.palette.error.main} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>

                {/* if subtree */}
                {isExpanded && (
                  <ComparisonTableModelSubTreeTable
                    trees={item.trees}
                    otherTreeFields={[]}
                    rowBackgroundColor={rowBackgroundColor}
                  />
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MultiTreeBookmarkListTable;

import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { Alert, Box, CircularProgress, Stack, Typography } from "@mui/material";
import Dropdown from "src/components/Dropdown";
import { useGetMultiTreeComparisonChartData } from "src/hooks/sessions";
import { getErrorMsg } from "src/utils/Utils";
import {
  defaultPopulationModelKeys,
  SessionResultsPlotType,
} from "src/utils/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MAX_SOLUTION_SIZE_FOR_BAR_CHART = 35; // ask to luis why
const colors = [
  "#0101ff",
  "#2ee09a",
  "#d9e32c",
  "#ff684d",
  "#E3D8F1",
  "#f405e6",
  "#3CDBD3",
  "#ed3615",
  "#E8E288",
  "#A0D2DB",
  "#726DA8",
  "#3CDBD3",
];

function ComparisonHistogram() {
  const chartRef = useRef();
  const { sessionId, combinedTreeList, comparisonFilters } =
    useMultiTreeSession();
  const [chartData, setChartData] = useState(undefined);

  const [histogramPlot, setHistogramPlot] = useState([]);

  const {
    mutateAsync: getChartData,
    isLoading,
    error,
  } = useGetMultiTreeComparisonChartData({
    sessionId,
  });

  useEffect(async () => {
    try {
      if (histogramPlot.length == 0) {
        return;
      }

      let allFields = [...defaultPopulationModelKeys];
      histogramPlot.forEach(item => {
        allFields.push(item.value);
      })

      const { labels, datasets } = await getChartData({
        filterInfo: comparisonFilters,
        fieldsInfo: allFields,
        prepPlotData: SessionResultsPlotType.BAR,
      });

      const data = { labels, datasets };
      setChartData(data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  }, [JSON.stringify(histogramPlot), JSON.stringify(comparisonFilters)]);

  const labels = useMemo(() => {
    if ((combinedTreeList || []).length === 0) {
      return [];
    }

    let metrics = new Set();
    combinedTreeList.forEach((item) => {
      if (item.modelOther) {
        Object.keys(item.modelOther).forEach((key) => {
          metrics.add(key);
        });
      }
    });

    const metricsArr = Array.from(metrics);
    if (histogramPlot.length == 0 && metricsArr.length > 0) {
      setHistogramPlot([
        {
          value: `other.${metricsArr[0]}`,
          label: metricsArr[0],
        },
      ]);
    }

    return metricsArr.map((item) => ({
      value: `other.${item}`,
      label: item,
    }));
  }, [combinedTreeList]);

  if (!combinedTreeList.length > 0)
    return <div className="tile-no-solutions">No solutions found</div>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div id="scatter-container">
      {error ? (
        <Box sx={{ width: "100%" }}>
          <Alert severity="error" sx={{ m: "1rem" }}>
            {" "}
            {getErrorMsg(error)}
          </Alert>
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              alignItems: "center",
              gap: ".5rem",
              mt: ".25rem",
            }}
          >
            <Dropdown
              value={histogramPlot}
              onChange={setHistogramPlot}
              options={labels.map((item) => {
                return { value: item.value, label: item.label };
              })}
              isSearchable={false}
              multiSelect={true}
            />
          </Box>
          {isLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "3rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : chartData ? (
            <Stack gap={"1.5rem"} mt={"-2rem"}>
              <Bar
                id="bar-chart"
                ref={chartRef}
                options={options}
                data={chartData}
              />
              <Alert severity="info" sx={{ margin: "1rem" }}>
                <Stack direction={"row"} gap={"1rem"}>
                  <Typography variant="caption">
                    <b>G: </b>Generation
                  </Typography>
                  <Typography variant="caption">
                    <b>M: </b>Model
                  </Typography>
                </Stack>
                <Typography variant="body2">
                  Above barchart shows only models data.
                </Typography>
              </Alert>
            </Stack>
          ) : (
            <Box sx={{ padding: ".5rem" }}>
              <Alert severity="info">
                Select axis fields to fill chart data.
              </Alert>
            </Box>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default ComparisonHistogram;

import { useState, useMemo, Fragment, useEffect } from "react";
import { BookmarkSimple, CaretUp, CaretDown } from "phosphor-react";
import {
  useBookmarkMultitreeSolution,
  useGetMultitreeSessionBookmarks,
  useRemoveBookmarkMultitreeSolution,
} from "../../../hooks/sessions";
import { StyledTableCell, StyledTableRow } from "../../common/TableItems";
import {
  IconButton,
  Stack,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  defaultMultiTreeTableHeaders,
  SessionStatus,
  SortDirection,
} from "src/utils/types";
import MosaicPanelEmptyMsg from "src/components/common/empty-message/MosaicPanelEmptyMsg";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { getCustomTableColor, getErrorMsg } from "src/utils/Utils";
import ComparisonTablePagination from "./ComparisonTablePagination";
import ComparisonTableModelSubTreeTable from "./ComparisonTableModelSubTree";
import useNotifier, { NotificationType } from "src/hooks/use-notify";

function ComparisonTable() {
  const { notify } = useNotifier();
  const {
    filteredSolutions,
    sessionStatus,
    sessionId,
    comparisonSort,
    setComparisonSort,
  } = useMultiTreeSession();
  const [expandedRows, setExpandedRows] = useState([]);
  const [bookmarkedItems, setBookmarkedItems] = useState(new Set());

  const isFailedOrFinished =
    sessionStatus === SessionStatus.FAILED ||
    sessionStatus === SessionStatus.FINISHED;

  const { data: multitreeBookmarksData } =
    useGetMultitreeSessionBookmarks(sessionId);

  const { mutateAsync: bookmark } = useBookmarkMultitreeSolution();
  const { mutateAsync: removeBookmark } = useRemoveBookmarkMultitreeSolution();

  const changeBookmarkedItems = (results = []) => {
    let bookmarkedIds = new Set();
    results.map((item) => {
      bookmarkedIds.add(item.id);
    });
    setBookmarkedItems(bookmarkedIds);
  };

  useEffect(() => {
    if (multitreeBookmarksData) {
      changeBookmarkedItems(multitreeBookmarksData.results || []);
    }
  }, [multitreeBookmarksData]);

  const otherFields = useMemo(() => {
    if (!filteredSolutions || filteredSolutions.length === 0) {
      return [];
    }

    let subTreeOtherFields = new Set();
    let modelOtherFields = new Set();

    filteredSolutions.forEach((item) => {
      Object.keys(item.other || {}).forEach((key) => {
        if (!modelOtherFields.has(key)) {
          modelOtherFields.add(key);
        }
      });

      (item.trees || []).forEach((tree) => {
        Object.keys(tree).forEach((key) => {
          if (
            !subTreeOtherFields.has(key) &&
            !defaultMultiTreeTableHeaders.has(key)
          ) {
            subTreeOtherFields.add(key);
          }
        });
      });
    });
    return {
      model: Array.from(modelOtherFields),
      tree: Array.from(subTreeOtherFields),
    };
  }, [filteredSolutions]);

  const openDetails = (index) => {
    setExpandedRows((prev) => [...prev, index]);
  };

  const closeDetails = (index) => {
    const filtered = expandedRows.filter((i) => i !== index);
    setExpandedRows(filtered);
  };

  const clickOnSort = (field) => {
    if (field === comparisonSort.key) {
      setComparisonSort(() => ({
        key: field,
        order:
          comparisonSort.order === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
      }));
    } else {
      setComparisonSort((prev) => ({ ...prev, key: field }));
    }
  };

  if (!filteredSolutions || !(filteredSolutions.length > 0)) {
    return isFailedOrFinished ? (
      <MosaicPanelEmptyMsg
        title={"No populations found"}
        desc={"make sure your algorithm outputs populations.csv"}
      />
    ) : (
      <div className="tile-no-solutions">No solutions found</div>
    );
  }

  const handleBookmark = (resultId, modelId) => {
    bookmark({ resultId, modelId })
      .then((data) => {
        changeBookmarkedItems(data.results || []);
      })
      .catch((err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      });
  };

  const handleRemoveBookmark = (modelId) => {
    if (!multitreeBookmarksData) {
      notify(NotificationType.ERROR, "Could not remove bookmarked.");
      return;
    }
    removeBookmark({ bookmarkModelId: multitreeBookmarksData.id, modelId })
      .then(() => {
        let bookmarkeds = new Set(Array.from(bookmarkedItems));
        bookmarkeds.delete(modelId);
        setBookmarkedItems(bookmarkeds);
      })
      .catch((err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      });
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left"></StyledTableCell>
              <StyledTableCell
                align="left"
                sortDirection={comparisonSort.order}
              >
                <TableSortLabel
                  active={comparisonSort.key === "generation"}
                  direction={comparisonSort.order}
                  onClick={() => clickOnSort("generation")}
                >
                  #
                </TableSortLabel>
              </StyledTableCell>
              {otherFields.model.map((field) => (
                <StyledTableCell
                  align="left"
                  sortDirection={comparisonSort.order}
                  key={field}
                >
                  <TableSortLabel
                    active={comparisonSort.key === `other.${field}`}
                    direction={comparisonSort.order}
                    onClick={() => clickOnSort(`other.${field}`)}
                  >
                    {field}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell align="right" />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {filteredSolutions.map((item) => {
              const isBookmarkedSolution = Boolean(
                bookmarkedItems.has(item.id)
              );
              const rowBackgroundColor = getCustomTableColor(
                Number(item.individual)
              );
              const isExpanded = expandedRows.some((i) => i == item.id);
              return (
                <Fragment>
                  <StyledTableRow key={item.id}>
                    <StyledTableCell align="left">
                      <IconButton
                        sx={{
                          width: "1.5rem",
                          height: "1.5rem",
                          padding: ".25rem",
                        }}
                        onClick={() =>
                          !isExpanded
                            ? openDetails(item.id)
                            : closeDetails(item.id)
                        }
                      >
                        {" "}
                        {isExpanded ? (
                          <CaretUp size={18} />
                        ) : (
                          <CaretDown size={18} />
                        )}
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="body2" sx={{ fontWeight: "600" }}>
                        G{item.generation || "?"}:M{item.individual}
                      </Typography>
                    </StyledTableCell>
                    {otherFields.model.map((field) => (
                      <StyledTableCell align="left">
                        {"other" in item ? item.other[field] || "-" : "-"}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell align="right">
                      <IconButton
                        sx={{ width: "32px", height: "32px" }}
                        onClick={() => {
                          isBookmarkedSolution
                            ? handleRemoveBookmark(item.id)
                            : handleBookmark(item.resultId, item.id);
                        }}
                      >
                        <BookmarkSimple
                          weight="fill"
                          size={17}
                          color={`${isBookmarkedSolution ? "#0101ff" : "#b9c3cb"}`}
                        />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>

                  {/* if subtree */}
                  {isExpanded && (
                    <ComparisonTableModelSubTreeTable
                      trees={item.trees}
                      otherTreeFields={otherFields.tree}
                      rowBackgroundColor={rowBackgroundColor}
                    />
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ComparisonTablePagination />
    </Stack>
  );
}

export default ComparisonTable;

import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";

export const useGetDatasets = (projectId) =>
  useQuery(["datasets", projectId], () => {
    return ApiClient.get(`/api/projects/${projectId}/datasets`);
  });

export const useGetDatasetData = ({
  projectId,
  datasetId,
  delimiter,
  fileName,
}) =>
  useQuery(
    ["datasets", projectId, datasetId, fileName],
    async () => {
      let res = await ApiClient.get(
        `/api/projects/${projectId}/datasets/${datasetId}/data`
      );

      const isCsv = fileName.split(".").pop() === "csv";
      if (!isCsv) return res;

      let lines = res.data.split("\n");
      res.data = {
        header: lines[0].split(delimiter ? delimiter : ","),
        rows: lines
          .slice(1)
          .map((line) => line.split(delimiter ? delimiter : ",")),
      };

      return res;
    },
    {
      enabled: Boolean(datasetId) && Boolean(fileName),
      retry: false,
    }
  );

const downloadDataset = async (projectId, datasetId) => {
  const { data } = await ApiClient.get(
    `/api/projects/${projectId}/datasets/${datasetId}/download`
  );
  return data;
};
export const useDownloadDataset = () => {
  return useMutation({
    mutationFn: ({ projectId, datasetId }) =>
      downloadDataset(projectId, datasetId),
    onSuccess: (res, params) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "text/csv" })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = `trustai_${params.datasetName}`;
      a.click();
      window.URL.revokeObjectURL(url);
    },
  });
};

export const useUploadDataset = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, formData }) =>
      ApiClient.post(`/api/projects/${projectId}/datasets`, formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("datasets");
      },
    }
  );
};

export const useDeleteDataset = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, datasetId }) =>
      ApiClient.delete(`/api/projects/${projectId}/datasets/${datasetId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("datasets");
      },
    }
  );
};

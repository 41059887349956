import { Fragment, useMemo, useState } from "react";
import { useGetPopulationsofSession, useGetSessions } from "src/hooks/sessions";
import {
  AlgorithmTypes,
  ExplainerTypes,
  ShapleyStatus,
  SortDirection,
} from "src/utils/types";
import { genericSort, getErrorMsg } from "src/utils/Utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  Alert,
  Skeleton,
  TableSortLabel,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router";
import { TableVirtuoso } from "react-virtuoso";
import PleaseWait from "../common/PleaseWait";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "../common/TableItems";
import { useRunShapley } from "src/hooks/feature-importance";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { X } from "phosphor-react";
import ShapleyAnalyseDialog from "./shapley-analyse-dialog/ShapleyAnalyseDialog";

const DEFAULT_SAMPLE_SIZE = 100;

const CreateNewShapleyDialog = ({ open, onClose }) => {
  const { notify } = useNotifier();
  const params = useParams();
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedExpression, setSelectedExpression] = useState(null);
  const [sortField, setSortField] = useState("fitness");
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
  const [sampleSize, setSampleSize] = useState(DEFAULT_SAMPLE_SIZE);
  const [explainer, setExplainer] = useState(ExplainerTypes.ADDITIVE);
  const [selectedShapleyId, setSelectedShapleyId] = useState(null);

  const { mutateAsync: runShapley, isLoading } = useRunShapley();

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id
  );

  const targetLabel = useMemo(() => {
    return selectedSession
      ? sessions.data.find((item) => item.id === selectedSession).target
      : "";
  }, [selectedSession]);

  const { data: allPopulationsIndividuals, isFetching: populationsFetching } =
    useGetPopulationsofSession({ sessionId: selectedSession });

  const allPopulations = useMemo(() => {
    if ((allPopulationsIndividuals || []).length === 0) {
      return [];
    } else {
      let solutionsList = [];
      for (const population in allPopulationsIndividuals) {
        solutionsList = solutionsList.concat(
          allPopulationsIndividuals[population]
        );
      }

      const sortedList = genericSort(solutionsList, sortField, sortDirection);
      return sortedList;
    }
  }, [allPopulationsIndividuals, sortDirection, sortField]);

  const handleSelectExpression = (populationItem) => {
    if (selectedExpression?.id === populationItem.id) {
      setSelectedExpression(null);
      return;
    }

    setSelectedExpression({
      id: populationItem.id,
      model: populationItem.model,
    });
  };

  const handleChangeSort = (field) => {
    const previousField = sortField;

    if (previousField === field) {
      setSortDirection(
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC
      );
    } else {
      setSortField(field);
    }
  };

  const handleRunShapley = () => {
    const body = {
      expr: selectedExpression.model,
      sampleSize: sampleSize,
      sessionId: selectedSession,
      explainer: explainer,
      allFeatures: [
        "MedInc",
        "HouseAge",
        "AveRooms",
        "AveBedrms",
        "Population",
        "AveOccup",
        "Latitude",
        "Longitude",
        "MedHouseVal",
      ],
    };

    runShapley({ body })
      .then((res) => {
        if (
          res.state === ShapleyStatus.CREATED ||
          res.state === ShapleyStatus.RUNNING
        ) {
          notify(
            NotificationType.SUCCESS,
            "Feature importance analyse started."
          );
          setSelectedShapleyId(res.id);
        }
      })
      .catch((err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      });
  };

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={onClose} fullScreen>
      <DialogTitle variant="h6" fontWeight={600}>
        Create Feature Importance
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: ".5rem", right: "1rem" }}
      >
        <X />
      </IconButton>
      <DialogContent
        dividers
        sx={{ paddingX: { xs: "1rem", sm: "2rem", md: "4rem" } }}
      >
        {sessionLoading ? (
          <PleaseWait />
        ) : (
          <Fragment>
            <Stack>
              {!sessionLoading && sessions?.data.length === 0 && (
                <Alert severity="info">
                  No session found. Please create a session first.
                </Alert>
              )}
              {!sessionLoading &&
                sessions?.data.length > 0 &&
                sessions?.data.filter(
                  (item) => item.algorithm.type === AlgorithmTypes.PREDICTIVE
                ).length === 0 && (
                  <Alert severity="warning">
                    Feature importance analyses only work with predictive
                    algorithms. You need to create and run session with
                    predictive type algorithms.
                  </Alert>
                )}
              {!sessionLoading &&
                sessions?.data.length > 0 &&
                sessions?.data.filter(
                  (item) => item.algorithm.type === AlgorithmTypes.PREDICTIVE
                ).length > 0 &&
                !selectedSession && (
                  <Alert severity="info">
                    Select a session to see previous explanations.
                  </Alert>
                )}
            </Stack>
            <Grid container alignItems="center" spacing={2} mt={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: ".5rem",
                  }}
                >
                  Select session
                </Typography>
                <Select
                  fullWidth
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                  disabled={sessionLoading}
                >
                  {(sessions ? sessions.data : [])
                    .filter(
                      (item) =>
                        item.algorithm.type === AlgorithmTypes.PREDICTIVE &&
                        !item.algorithm.multiTree
                    )
                    .map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={8}>
                {targetLabel && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginBottom: ".5rem",
                    }}
                  >
                    <b>Target Label:</b> {targetLabel}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {selectedSession && (
              <Grid container spacing={3} sx={{ mt: "2rem" }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Sample size"
                    type="number"
                    value={sampleSize}
                    onChange={(e) => setSampleSize(e.target.value)}
                    helperText="Number of random instances that will be used to determine the background distribution"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="explainer">Explainer Type</InputLabel>
                    <Select
                      fullWidth
                      id="explainer"
                      name="explainer"
                      label="Explainer Type"
                      value={explainer}
                      onChange={(e) => setExplainer(e.target.value)}
                    >
                      {Object.entries(ExplainerTypes).map(([_, val]) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {explainer !== ExplainerTypes.ADDITIVE && (
                    <Alert sx={{ mt: ".5rem" }} severity="warning">
                      This can take long time to finish.
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: ".5rem",
                      fontFamily: "Titillium Web",
                    }}
                  >
                    Select expression
                  </Typography>
                  {populationsFetching ? (
                    <>
                      {new Array(5).fill().map(() => (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={30}
                          style={{ marginBottom: ".25rem" }}
                        />
                      ))}
                    </>
                  ) : (allPopulationsIndividuals || []).length === 0 ? (
                    <Grid>
                      <Alert severity="info">
                        No expression found. Please create or run a session.
                      </Alert>
                    </Grid>
                  ) : (
                    <TableVirtuoso
                      style={{ height: "400px" }}
                      data={allPopulations}
                      components={VirtualizedTableComponents}
                      fixedHeaderContent={() => (
                        <StyledTableRow>
                          <StyledTableCell align="left">#</StyledTableCell>
                          <StyledTableCell align="left">
                            Expression
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            key={"fitness"}
                            sortDirection={
                              sortField === "fitness" ? sortDirection : false
                            }
                          >
                            <TableSortLabel
                              active={sortField === "fitness"}
                              direction={
                                sortField === "fitness"
                                  ? sortDirection
                                  : SortDirection.ASC
                              }
                              onClick={() => handleChangeSort("fitness")}
                            >
                              Fitness
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            key={"size"}
                            sortDirection={
                              sortField === "size" ? sortDirection : false
                            }
                          >
                            <TableSortLabel
                              active={sortField === "size"}
                              direction={
                                sortField === "size"
                                  ? sortDirection
                                  : SortDirection.ASC
                              }
                              onClick={() => handleChangeSort("size")}
                            >
                              Size
                            </TableSortLabel>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                      itemContent={(index, solution) => (
                        <>
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={
                                selectedExpression
                                  ? solution.id === selectedExpression.id
                                    ? true
                                    : false
                                  : false
                              }
                              onChange={() => handleSelectExpression(solution)}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {solution.model}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {solution.fitness}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {solution.size}
                          </StyledTableCell>
                        </>
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            )}

            {selectedShapleyId && (
              <ShapleyAnalyseDialog
                id={selectedShapleyId}
                sessionId={selectedSession}
                onClose={() => setSelectedShapleyId(null)}
              />
            )}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ paddingX: "1rem" }}>
        <Button
          sx={{ height: "34px" }}
          color="error"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          size="small"
          disabled={!selectedExpression}
          sx={{ height: "34px" }}
          startIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                sx={{ color: (t) => t.palette.primary.contrastText }}
              />
            ) : undefined
          }
          onClick={handleRunShapley}
        >
          ANALYSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewShapleyDialog;

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { MULTI_TREE_FIRST_PAGE, PaginationLimits } from "src/utils/types";

const ComparisonTablePagination = () => {
  const { filteredSolutions, comparisonPagination, setComparisonPagination } =
    useMultiTreeSession();

  const handleChange = (_, value) => {
    setComparisonPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const changeLimit = (e) => {
    setComparisonPagination((prev) => ({
      ...prev,
      page: MULTI_TREE_FIRST_PAGE,
      limit: e.target.value,
    }));
  };

  const totalPageSize = Math.ceil(
    Number(comparisonPagination.itemCount) / Number(comparisonPagination.limit)
  );

  return (
    <Box
      sx={{
        marginTop: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        flexWrap: "wrap",
        padding: ".5rem",
      }}
    >
      <Pagination
        count={totalPageSize}
        color="primary"
        disabled={!filteredSolutions}
        page={comparisonPagination.page}
        onChange={handleChange}
      />
      <FormControl sx={{ width: "5rem" }} variant="outlined" size="small">
        <InputLabel id="explainer">Limit</InputLabel>
        <Select
          fullWidth
          id="explainer"
          name="explainer"
          label="Limit"
          disabled={!filteredSolutions}
          value={comparisonPagination.limit}
          onChange={changeLimit}
        >
          {Object.entries(PaginationLimits).map(([_, val]) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ComparisonTablePagination;

import React from "react";
import { GENERAL_ERROR_MESSAGE, SortDirection, TableColors } from "./types";

export const mutateProp = (object, path, value) => {
  const mutateAux = (object, path, value) => {
    if (path.length === 1) {
      object[path[0]] = value;
    } else if (path.length === 0) {
      object = value;
    } else {
      if (object[path[0]])
        return mutateProp(object[path[0]], path.slice(1), value);
      else {
        object[path[0]] = {};
        return mutateProp(object[path[0]], path.slice(1), value);
      }
    }
  };

  mutateAux(object, path, value);
  return object;
};

export const useStickyState = (defaultValue, key) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });

  React.useEffect(() => {
    const currentState = { ...value };
    window.localStorage.setItem(key, JSON.stringify(currentState));
  }, [key, value]);

  return [value, setValue];
};

export function getErrorMsgWithStatusCode(error) {
  if (error.response) {
    const code = error.response.status;
    let message =
      error.response.data?.message ||
      error.response.statusText ||
      "Error occured. Please try later!";
    return code + " - " + message;
  } else if (error.code && error.message) {
    return error.code + " - " + error.message;
  } else if (error.request) {
    return "Network error: Maybe no connection";
  } else {
    return "Something went wrong";
  }
}

export const getErrorMsg = (error) => {
  return error?.response?.data?.message || GENERAL_ERROR_MESSAGE;
};

export const getBlobTypeErrMsg = async (error) => {
  let message = "";
  const errData = await (error.response?.data).text();
  message = JSON.parse(errData)?.message || GENERAL_ERROR_MESSAGE;
  return message;
};

export const getCSSVar = (name) => {
  const style = getComputedStyle(document.body);
  return style.getPropertyValue(`--${name}`).trim();
};

export const genericSort = (list, field, sortDirection) => {
  return [...list].sort((a, b) => {
    if (a.hasOwnProperty(field)) {
      if (typeof a[field] === "number" && typeof b[field] === "number") {
        return (
          (sortDirection === SortDirection.ASC ? a[field] : b[field]) -
          (sortDirection === SortDirection.ASC ? b[field] : a[field])
        );
      } else {
        return (
          (sortDirection === SortDirection.ASC
            ? a[field]
            : b[field]
          )?.toString() || ""
        ).localeCompare(
          (sortDirection === SortDirection.ASC
            ? b[field]
            : a[field]
          )?.toString() || "",
          undefined,
          { numeric: true }
        );
      }
    } else if (
      a.other?.hasOwnProperty(field) &&
      b.other?.hasOwnProperty(field)
    ) {
      if (typeof a[field] === "number" && typeof b[field] === "number") {
        return (
          (sortDirection === SortDirection.ASC
            ? a.other[field]
            : b.other[field]) -
          (sortDirection === SortDirection.ASC
            ? b.other[field]
            : a.other[field])
        );
      } else {
        return (
          (sortDirection === SortDirection.ASC
            ? a.other[field]
            : b.other[field]
          )?.toString() || ""
        ).localeCompare(
          (sortDirection === SortDirection.ASC
            ? b.other[field]
            : a.other[field]
          )?.toString() || "",
          undefined,
          { numeric: true }
        );
      }
    }
    return -1;
  });
};


export const getCustomTableColor = (id) => {
  let color = TableColors[id % TableColors.length];
  return color;
}
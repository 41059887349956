import { Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { StyledTableCell, StyledTableRow } from "../../common/TableItems";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import useNotifier, { NotificationType } from "src/hooks/use-notify";

const ComparisonTableModelSubTreeTable = ({
  rowBackgroundColor,
  trees = [],
  otherTreeFields = [],
}) => {
  const { notify } = useNotifier();

  console.log('trees', trees)
  console.log('otherTreeFields', otherTreeFields)

  return (
    <StyledTableRow sx={{ backgroundColor: rowBackgroundColor }}>
      <StyledTableCell colSpan={12}>
        <Fragment sx={{ padding: ".5rem" }}>
          {(trees || []).length === 0 ? (
            <Typography>No tree exist.</Typography>
          ) : (
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table>
                <TableHead>
                  <StyledTableCell align="left">#</StyledTableCell>
                  <StyledTableCell align="left">Expression</StyledTableCell>
                  {otherTreeFields.map((field) => (
                    <StyledTableCell align="left" key={field}>
                      {field}
                    </StyledTableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {trees.map((tree, index) => {
                    return (
                      <StyledTableRow>
                        <StyledTableCell align="left">
                          {index + 1}.
                        </StyledTableCell>
                        <CopyToClipboard
                          text={typeof tree == "string" && tree}
                          onCopy={() =>
                            notify(NotificationType.SUCCESS, "Copied!")
                          }
                        >
                          <StyledTableCell align="left" sx={{ cursor: "copy" }}>
                            {typeof tree == "string" ? tree : tree.expr}
                          </StyledTableCell>
                        </CopyToClipboard>
                        {otherTreeFields.map((key) => (
                          <StyledTableCell align="left">
                            {tree[key] || "-"}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Fragment>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ComparisonTableModelSubTreeTable;

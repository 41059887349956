import { Fragment } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import Dropdown from "../Dropdown";
import SingleTreeBookmarkListTable from "./SingleTreeBookmarkListTable";
import Alert from "../Alert";
import MultiTreeBookmarkListTable from "./MultiTreeBookmarkListTable";

const SessionBookmarksPanel = ({
  sessions,
  sessionLoading,
  selectedSession,
  setSelectedSession,
  sessionBookmarksLoading,
  existingBookmarks,
  setExistingBookmarks,
  sortField,
  sortDirection,
  onChangeSort,
}) => {
  return (
    <Fragment>
      {!sessionLoading && sessions?.data.length === 0 && (
        <Grid>
          <Alert
            type="info"
            message="No session found. Please create a session first."
          />
        </Grid>
      )}
      {!sessionLoading && sessions?.data.length > 0 && !selectedSession && (
        <Grid>
          <Alert type="info" message="Select a session to see bookmarks." />
        </Grid>
      )}
      {sessions?.data.length > 0 && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <Typography className="form-item-label">Select session</Typography>
            <Dropdown
              value={selectedSession}
              onChange={setSelectedSession}
              isDisabled={sessionLoading}
              options={(sessions
                ? sessions.data.length > 0
                  ? sessions.data
                  : []
                : []
              ).map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                  dataset: item.dataset,
                  isMultiTree: Boolean(item.algorithm?.multiTree),
                };
              })}
            />
          </Grid>
        </Grid>
      )}
      {sessionBookmarksLoading ? (
        <section id="loading-section" style={{ marginTop: ".75rem" }}>
          <Typography className="form-item-label">Bookmarkeds</Typography>
          {new Array(8).fill().map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              animation="wave"
              width="100%"
              height={30}
              style={{ marginBottom: ".25rem" }}
            />
          ))}
        </section>
      ) : existingBookmarks.length === 0 && selectedSession ? (
        <Grid container>
          <Grid item xs={12} mt={2}>
            <Alert type="info" message="No bookmarked solution found." />
          </Grid>
        </Grid>
      ) : selectedSession ? (
        selectedSession.isMultiTree ? (
          <MultiTreeBookmarkListTable
            existingBookmarks={existingBookmarks}
            setExistingBookmarks={setExistingBookmarks}
            sortField={sortField}
            sortDirection={sortDirection}
            onChangeSort={onChangeSort}
          />
        ) : (
          <SingleTreeBookmarkListTable
            existingBookmarks={existingBookmarks}
            setExistingBookmarks={setExistingBookmarks}
            sortField={sortField}
            sortDirection={sortDirection}
            onChangeSort={onChangeSort}
          />
        )
      ) : null}
    </Fragment>
  );
};

export default SessionBookmarksPanel;

import React, { useState, useRef, useMemo, useEffect, Fragment } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { useGetMultiTreeComparisonChartData } from "src/hooks/sessions";
import { defaultPopulationModelKeys, SessionResultsPlotType } from "src/utils/types";
import { Alert, Box, CircularProgress } from "@mui/material";
import { getErrorMsg } from "src/utils/Utils";
import Dropdown from "src/components/Dropdown";
import useNotifier, { NotificationType } from "src/hooks/use-notify";

const colors = [
  "#0101ff",
  "#2ee09a",
  "#d9e32c",
  "#ff684d",
  "#E3D8F1",
  "#f405e6",
  "#3CDBD3",
  "#ed3615",
  "#E8E288",
  "#A0D2DB",
  "#726DA8",
  "#3CDBD3",
];

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  zoomPlugin
);

function ComparisonScatter() {
  const {notify} = useNotifier()
  const { selectedSession, combinedTreeList, comparisonFilters, sessionId } =
    useMultiTreeSession();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(undefined);

  const [scatterplotY, setscatterplotY] = useState({
    value: "",
    label: "select y axis field",
  });

  const [scatterplotX, setscatterplotX] = useState({
    value: "",
    label: "select x axis field",
  });

  const {
    mutateAsync: getChartData,
    isLoading,
    error,
  } = useGetMultiTreeComparisonChartData({
    sessionId,
  });

  useEffect(async () => {
    try {
      if(!scatterplotX.value || !scatterplotY.value){
        return
      }

      let allFields = [...defaultPopulationModelKeys];
      allFields.push(scatterplotX.value);
      allFields.push(scatterplotY.value);

      const {datasets} = await getChartData({
        filterInfo: comparisonFilters,
        fieldsInfo: allFields,
        prepPlotData: SessionResultsPlotType.SCATTER
      });

      const data = { datasets };
      setChartData(data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  }, [
    scatterplotX.value,
    scatterplotY.value,
    JSON.stringify(comparisonFilters),
  ]);

  const labels = useMemo(() => {
    if ((combinedTreeList || []).length === 0) {
      return [];
    }

    let metrics = new Set();
    combinedTreeList.forEach((item) => {
      if (item.modelOther) {
        Object.keys(item.modelOther).forEach((key) => {
          metrics.add(key);
        });
      }
    });

    const metricsArr = Array.from(metrics)
    if(!scatterplotX.value && metricsArr.length > 0){
      setscatterplotX({
        value: `other.${metricsArr[0]}`,
        label: metricsArr[0],
      })
    }
    if(!scatterplotY.value && metricsArr.length > 1){
      setscatterplotY({
        value: `other.${metricsArr[1]}`,
        label: metricsArr[1],
      })
    }

    return metricsArr.map((item) => ({
      value: `other.${item}`,
      label: item,
    }));
  }, [combinedTreeList]);

  if (!selectedSession || !combinedTreeList || combinedTreeList?.length === 0)
    return <div className="tile-no-solutions">No solutions found</div>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw.identifier}: (${context.raw.x}, ${context.raw.y})`;
          },
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: scatterplotY.label,
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: scatterplotX.label,
        },
      },
    },
  };

  return (
    <div id="scatter-container">
      {error ? (
        <Box sx={{ width: "100%" }}>
          <Alert severity="error" sx={{ m: "1rem" }}>
            {getErrorMsg(error)}
          </Alert>
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              alignItems: "center",
              gap: ".5rem",
              mt: ".25rem",
              mb: "-2rem",
            }}
          >
            {scatterplotY && (
              <Dropdown
                value={{ value: scatterplotY.value, label: scatterplotY.label }}
                onChange={setscatterplotY}
                options={labels.map((item) => {
                  return { value: item.value, label: item.label };
                })}
                isSearchable={false}
                p
              />
            )}
            {scatterplotX && (
              <Dropdown
                value={{ value: scatterplotX.value, label: scatterplotX.label }}
                onChange={setscatterplotX}
                options={labels.map((item) => {
                  return { value: item.value, label: item.label };
                })}
                isSearchable={false}
              />
            )}
          </Box>
          {isLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "2.5rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : chartData ? (
            <Scatter options={options} data={chartData} ref={chartRef} />
          ) : (
            <Box sx={{ padding: ".5rem" }}>
              <Alert severity="info">
                Select axis fields to fill chart data.
              </Alert>
            </Box>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default ComparisonScatter;

import { useEffect, useState } from "react";
import { ArrowCounterClockwise, SlidersHorizontal } from "phosphor-react";
import {
  Alert,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import {
  defaultMultiTreeTableHeaders,
  defaultPopulationModelKeys,
  FilterOperators,
  MultiTreeFilterType,
  TreeMetricFilterType,
} from "src/utils/types";

function ComparisonFilters() {
  const { filteredSolutions, comparisonFilters, setComparisonFilters } =
    useMultiTreeSession();
  const [localAppliedFilters, setLocalAppliedFilters] = useState([]);
  const [modelBasedFilters, setModelBasedFilters] = useState([]);
  const [treeBasedFilters, setTreeBasedFilters] = useState([]);
  const [treeMetricFilterTypes, setTreeMetricFilterTypes] = useState(new Map());

  useEffect(() => {
    setLocalAppliedFilters(comparisonFilters);
  }, [JSON.stringify(comparisonFilters)]);

  useEffect(() => {
    if (
      (!filteredSolutions || filteredSolutions.length === 0) &&
      modelBasedFilters.length == 0 &&
      treeBasedFilters.length == 0
    ) {
      return;
    }

    let modelFilters = new Set(defaultPopulationModelKeys);
    let treeFilters = new Set();

    filteredSolutions.forEach((item) => {
      Object.keys(item.other || {}).forEach((key) => {
        if (!modelFilters.has(key) && typeof item.other[key] == "number") {
          modelFilters.add(key);
        }
      });

      (item.trees || []).forEach((tree) => {
        Object.keys(tree).forEach((key) => {
          if (
            !treeFilters.has(key) &&
            !defaultMultiTreeTableHeaders.has(key) &&
            typeof tree[key] == "number"
          ) {
            treeFilters.add(key);
          }
        });
      });
    });

    setModelBasedFilters(Array.from(modelFilters));
    setTreeBasedFilters(Array.from(treeFilters));
  }, [(filteredSolutions || []).length > 0]);

  const addFilter = (key, operator, value, type) => {
    let existingFilters = [...localAppliedFilters];
    const isModel = type == MultiTreeFilterType.MODEL;
    const modelKey = isModel
      ? defaultPopulationModelKeys.includes(key)
        ? key
        : `other.${key}`
      : key;

    let filter = existingFilters.find(
      (item) =>
        item.key === modelKey && item.op === operator && item.metric == type
    );

    if (filter) {
      existingFilters = value
        ? existingFilters.map((item) => {
            if (item.key === modelKey && item.op === operator) {
              return { ...item, value: Number(value) };
            } else {
              return item;
            }
          })
        : existingFilters.filter((item) => {
            const sameKeys = item.key === modelKey;
            const sameOp = item.op === operator;
            const sameItem = sameKeys && sameOp;
            return !sameItem;
          });
    } else {
      let newFilter = {
        key: modelKey,
        op: operator,
        value: Number(value),
        metric: type,
      };
      existingFilters.push(newFilter);
    }

    setLocalAppliedFilters(existingFilters);
  };

  const onChangeTreeMetricType = (item, type) => {
    let existingFilters = treeMetricFilterTypes;
    existingFilters.set(item, type);
    setTreeMetricFilterTypes(new Map(existingFilters));
  };

  const applyFilters = () => {
    let filters = [...localAppliedFilters].map((item) => {
      if (item.metric == MultiTreeFilterType.TREE) {
        return {
          ...item,
          treeMetricType:
            treeMetricFilterTypes.get(item.key) ||
            TreeMetricFilterType.UNIVERSAL,
        };
      } else {
        return item;
      }
    });
    setComparisonFilters(filters);
  };

  const resetFilters = () => {
    setComparisonFilters([]);
  };

  return (
    <div style={{ paddingBottom: "5rem" }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={".5rem"}
      >
        <h5 style={{ margin: 0 }}>Filter Data</h5>
        <Button
          size="small"
          startIcon={<ArrowCounterClockwise />}
          onClick={resetFilters}
        >
          Reset
        </Button>
      </Stack>
      <Alert severity="info" sx={{ margin: ".25rem 0 1rem 0" }}>
        Both min max filters are inclusive
      </Alert>
      <Stack gap={".75rem"} mb={"1.5rem"} mt={"1.5rem"}>
        {modelBasedFilters.map((item) => {
          const minVal =
            localAppliedFilters.find(
              (lf) => lf.key == item && lf.op == FilterOperators.MIN
            )?.value || undefined;

          const maxVal =
            localAppliedFilters.find(
              (lf) => lf.key == item && lf.op == FilterOperators.MAX
            )?.value || undefined;
          return (
            <Stack gap={".25rem"} key={item}>
              <Typography variant="body2" fontWeight={600}>
                {item}
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={"1rem"}>
                <TextField
                  key={`model-${item}-min`}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="min"
                  type="number"
                  value={minVal}
                  inputProps={{ min: 0 }}
                  onChange={(e) =>
                    addFilter(
                      item,
                      FilterOperators.MIN,
                      e.target.value,
                      MultiTreeFilterType.MODEL
                    )
                  }
                />
                <TextField
                  key={`model-${item}-max`}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="max"
                  type="number"
                  value={maxVal}
                  inputProps={{ min: 0 }}
                  onChange={(e) =>
                    addFilter(
                      item,
                      FilterOperators.MAX,
                      e.target.value,
                      MultiTreeFilterType.MODEL
                    )
                  }
                />
              </Stack>
            </Stack>
          );
        })}
        {treeBasedFilters.map((item) => (
          <Stack gap={".25rem"} key={item}>
            <Stack direction={"row"} gap={"1rem"}>
              <Typography variant="body2" fontWeight={600}>
                {item}
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={".5rem"}>
                <IconButton
                  sx={{ padding: "0rem", height: "fit-content" }}
                  onClick={() =>
                    onChangeTreeMetricType(item, TreeMetricFilterType.UNIVERSAL)
                  }
                >
                  <Chip
                    label={TreeMetricFilterType.UNIVERSAL}
                    variant={
                      !treeMetricFilterTypes.get(item) ||
                      treeMetricFilterTypes.get(item) ==
                        TreeMetricFilterType.UNIVERSAL
                        ? "filled"
                        : "outlined"
                    }
                    size="small"
                    sx={{ fontSize: ".675rem" }}
                  />
                </IconButton>
                <IconButton
                  sx={{ padding: "0rem", height: "fit-content" }}
                  onClick={() =>
                    onChangeTreeMetricType(
                      item,
                      TreeMetricFilterType.EXISTENSIAL
                    )
                  }
                >
                  <Chip
                    label={TreeMetricFilterType.EXISTENSIAL}
                    variant={
                      treeMetricFilterTypes.get(item) ==
                      TreeMetricFilterType.EXISTENSIAL
                        ? "filled"
                        : "outlined"
                    }
                    size="small"
                    sx={{ fontSize: ".675rem" }}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"1rem"}>
              <TextField
                key={`tree-${item}-min`}
                fullWidth
                variant="outlined"
                size="small"
                label="min"
                type="number"
                inputProps={{ min: 0 }}
                onChange={(e) =>
                  addFilter(
                    item,
                    FilterOperators.MIN,
                    e.target.value,
                    MultiTreeFilterType.TREE
                  )
                }
              />
              <TextField
                key={`tree-${item}-max`}
                fullWidth
                variant="outlined"
                size="small"
                label="max"
                type="number"
                inputProps={{ min: 0 }}
                onChange={(e) =>
                  addFilter(
                    item,
                    FilterOperators.MAX,
                    e.target.value,
                    MultiTreeFilterType.TREE
                  )
                }
              />
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Stack gap={".5rem"}>
        <button
          id="apply-filters-button"
          className="icon-button"
          type="button"
          disabled={
            modelBasedFilters.length == 0 && treeBasedFilters.length == 0
          }
          onClick={applyFilters}
        >
          <SlidersHorizontal size={18} color="#ffffff" weight="bold" />
          <span>Apply</span>
        </button>
      </Stack>
    </div>
  );
}

export default ComparisonFilters;

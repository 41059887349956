import { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { SessionStatus, SessionTabs } from "src/utils/types";
import Comparison from "src/components/multitree/filter/Comparison";
import Training from "src/components/multitree/training/Training";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import Evaluation from "src/components/multitree/evaluation/Evaluation";
import { Tab, Tabs } from "@mui/material";

const MultiTreeSession = () => {
  const [selectedTab, setSelectedTab] = useState(SessionTabs.TRAINING);
  const {
    session,
    project,
    sessionStatus,
    filteredSolutions,
    combinedTreeList,
    isTestSession,
    isTrainSession,
  } = useMultiTreeSession();

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div id="model-page">
      <div id="session">
        {session && (
          <>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              sx={{ marginBottom: ".5rem" }}
            >
              <Tab
                value={SessionTabs.TRAINING}
                label={isTestSession ? "Test Results" : "Training"}
                sx={{ textTransform: "none" }}
              />
              {isTrainSession && (
                <Tab
                  value={SessionTabs.FILTERING}
                  label="Filter"
                  sx={{ textTransform: "none" }}
                />
              )}
              {filteredSolutions && (
                <Tab
                  value={SessionTabs.EVALUATION}
                  label="Evaluation"
                  sx={{ textTransform: "none" }}
                />
              )}
            </Tabs>

            {project && session && (
              <div className="model-page-content">
                <section
                  className={`mosaic-panel-section ${
                    selectedTab === SessionTabs.TRAINING ? "active" : "passive"
                  }`}
                >
                  <Training />
                </section>
                <section
                  className={`mosaic-panel-section ${
                    selectedTab === SessionTabs.FILTERING ? "active" : "passive"
                  }`}
                >
                  <Comparison />
                </section>
                {combinedTreeList && (
                  <section
                    className={`mosaic-panel-section ${
                      selectedTab === SessionTabs.EVALUATION
                        ? "active"
                        : "passive"
                    }`}
                  >
                    <Evaluation />
                  </section>
                )}
                {isTestSession && sessionStatus === SessionStatus.FINISHED && (
                  <section
                    className={`mosaic-panel-section ${
                      selectedTab === SessionTabs.DASHBOARD
                        ? "active"
                        : "passive"
                    }`}
                  >
                    <p>redash</p>
                  </section>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MultiTreeSession;

import { useRef, useEffect, useState } from "react";
import { Xwrapper } from "react-xarrows";
import { MagnifyingGlassPlus, MagnifyingGlassMinus } from "phosphor-react";
import { buildTree, mapParsedExpression } from "./utils";
import { Box, Typography } from "@mui/material";

const TreeVisualizer = ({ parsedExpTree, selectedTreeItem }) => {
  const vizRef = useRef(null);
  const treeRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [xArrows, setXArrows] = useState([]);
  const [tree, setTree] = useState(null);

  useEffect(() => {
    if (parsedExpTree) {
      renderTreeFromParsedExpression();
    }
  }, [parsedExpTree]);

  useEffect(() => {
    vizRef.current.style["transform"] = "scale(1)";
  }, [vizRef]);

  const renderTreeFromParsedExpression = () => {
    const allNodes = [];
    let depth = 0;

    mapParsedExpression(parsedExpTree, allNodes, depth, null);
    createTree(allNodes.length > 0 ? allNodes[0] : null);
  };

  const createTree = (node) => {
    let arrows = [];
    let copiedNode = { ...node };
    const treeDOM = buildTree(copiedNode, arrows);

    setXArrows(arrows);
    setTree(treeDOM);
  };

  const zoom = async (value) => {
    let currentScale = vizRef.current.style["transform"];
    let currentZoom = currentScale.substring(
      currentScale.indexOf("(") + 1,
      currentScale.lastIndexOf(")")
    );
    let scale = Number(currentZoom) + value;
    let el = vizRef.current;
    let transformOrigin = [0, 0];
    let p = ["webkit", "moz", "ms", "o"],
      s = "scale(" + scale + ")",
      oString =
        transformOrigin[0] * 100 + "% " + transformOrigin[1] * 100 + "%";

    for (let i = 0; i < p.length; i++) {
      el.style[p[i] + "Transform"] = s;
      el.style[p[i] + "TransformOrigin"] = oString;
    }

    el.style["transform"] = s;
    el.style["transformOrigin"] = oString;
    setZoomLevel((prev) => prev + value); // to rerender tree and arrows
  };

  function onWheel(e) {
    if (e.altKey || e.ctrlKey || e.shiftKey || e.metaKey) {
      e.deltaY > 0 ? zoom(0.01) : zoom(-0.01);
    }
  }

  // console.log('selectedTreeItem', selectedTreeItem)

  return (
    <Box id="tree-viz-container">
      <Xwrapper>{xArrows}</Xwrapper>
      <div ref={treeRef} id="tree-visualizer" onWheel={(e) => onWheel(e)}>
        <div id="tree-container" ref={vizRef}>
          {tree}
        </div>

        <span id="tree-zoomer">
          <button
            id="zoom-in"
            className="zoom-button"
            type="button"
            onClick={() => {
              zoom(0.1);
            }}
          >
            <MagnifyingGlassPlus size={14} weight="bold" />
          </button>
          <button
            id="zoom-out"
            className="zoom-button"
            type="button"
            onClick={() => {
              zoom(-0.1);
            }}
          >
            <MagnifyingGlassMinus size={14} weight="bold" />
          </button>
        </span>
        <span id="tree-identifier">
          <Typography variant="caption">
            <b>G{selectedTreeItem?.generation || "?"}:M{selectedTreeItem?.individual || "?"}</b>
          </Typography>
          <Typography variant="caption">
            <b>
              Tree-{selectedTreeItem ? selectedTreeItem.treeIndex + 1 : "-"}
            </b>
          </Typography>
        </span>
      </div>
    </Box>
  );
};

export default TreeVisualizer;

import React, { useState, useEffect, useContext, createContext } from "react";
import { TRUST_USER_LOCAL_KEY, UserStatus } from "src/utils/types";
import ApiClient from "src/axios";
import axios from "axios";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(
    localStorage.getItem(TRUST_USER_LOCAL_KEY)
      ? JSON.parse(localStorage.getItem(TRUST_USER_LOCAL_KEY))
      : null,
  );
  const [loading, setLoading] = useState(true);
  const [initialCurrentUserLoad, setInitialCurrentUserLoad] = useState(false);

  const signin = async (email, password) => {
    return axios
      .post((process.env.REACT_APP_TRUST_URL || "") + "/api/users/signin", {
        email,
        password,
      })
      .then((res) => {
        const userRes = res.data;
        if (userRes.status === UserStatus.ACTIVE) {
          setUser(userRes);
          localStorage.setItem(TRUST_USER_LOCAL_KEY, JSON.stringify(userRes));
          return userRes;
        } else if (userRes.status === UserStatus.PENDING) {
          throw new Error("status_pending_error");
        }
      });
  };

  const signup = async (
    email,
    firstname,
    lastname,
    password,
    confirmPassword,
  ) => {
    return axios
      .post((process.env.REACT_APP_TRUST_URL || "") + "/api/users/signup", {
        email,
        firstname,
        lastname,
        password,
        confirmPassword,
      })
      .then((res) => {
        const userRes = res.data;
        if (userRes.status !== UserStatus.PENDING) {
          setUser(userRes);
          localStorage.setItem(TRUST_USER_LOCAL_KEY, JSON.stringify(userRes));
        }
        return userRes;
      });
  };

  const signout = () => {
    setUser(null);
    localStorage.clear();
    return user;
  };

  const sendPasswordResetEmail = (email) => {
    return true;
  };

  const confirmPasswordReset = (code, password) => {
    return true;
  };

  const loadCurrentUser = async () => {
    setLoading(true);
    return ApiClient.get("/api/users/currentuser")
      .then((res) => {
        const userRes = res.data;
        if (userRes?.status == UserStatus.ACTIVE) {
          const userData = {
            ...userRes,
            jwt: user?.jwt
          }
          setUser(userData);
          localStorage.setItem(
            TRUST_USER_LOCAL_KEY,
            JSON.stringify(userData),
          );
          return userRes;
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setInitialCurrentUserLoad(true);
        setLoading(false);
      });
  };

  // Return the user object and auth methods
  return {
    user,
    setUser,
    loading,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    initialCurrentUserLoad,
    loadCurrentUser
  };
}

import { Fragment } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import Dropdown from "../Dropdown";
import SingleTreeBookmarkListTable from "./SingleTreeBookmarkListTable";
import Alert from "../Alert";
import MultiTreeBookmarkListTable from "./MultiTreeBookmarkListTable";

const AlgorithmBookmarksPanel = ({
  existingBookmarks,
  setExistingBookmarks,
  algorithmsloading,
  algorithms,
  selectedAlgorithm,
  setSelectedAlgorithm,
  algorithmBookmarksLoading,
  sortField,
  sortDirection,
  onChangeSort,
}) => {
  return (
    <Fragment>
      {!algorithmsloading && algorithms?.data.length === 0 && (
        <Grid>
          <Alert
            type="info"
            message="No algorithm found. Please create a algorithm first."
          />
        </Grid>
      )}
      {!algorithmsloading &&
        algorithms?.data.length > 0 &&
        !selectedAlgorithm && (
          <Grid>
            <Alert type="info" message="Select a algorithm to see bookmarks." />
          </Grid>
        )}
      {algorithms?.data.length > 0 && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <Typography className="form-item-label">
              Select algorithm
            </Typography>
            <Dropdown
              value={selectedAlgorithm}
              onChange={setSelectedAlgorithm}
              isDisabled={algorithmsloading}
              options={(algorithms
                ? algorithms.data.length > 0
                  ? algorithms.data
                  : []
                : []
              ).map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                  dataset: item.dataset,
                  isMultiTree: Boolean(item.multiTree),
                };
              })}
            />
          </Grid>
        </Grid>
      )}
      {algorithmBookmarksLoading ? (
        <section id="loading-section" style={{ marginTop: ".75rem" }}>
          <Typography className="form-item-label">Bookmarkeds</Typography>
          {new Array(8).fill().map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              animation="wave"
              width="100%"
              height={30}
              style={{ marginBottom: ".25rem" }}
            />
          ))}
        </section>
      ) : existingBookmarks.length === 0 && selectedAlgorithm ? (
        <Grid container>
          <Grid item xs={12} mt={2}>
            <Alert type="info" message="No bookmarked solution found." />
          </Grid>
        </Grid>
      ) : selectedAlgorithm ? (
        selectedAlgorithm.isMultiTree ? (
          <MultiTreeBookmarkListTable
            existingBookmarks={existingBookmarks}
            setExistingBookmarks={setExistingBookmarks}
            sortField={sortField}
            sortDirection={sortDirection}
            onChangeSort={onChangeSort}
          />
        ) : (
          <SingleTreeBookmarkListTable
            existingBookmarks={existingBookmarks}
            setExistingBookmarks={setExistingBookmarks}
            sortField={sortField}
            sortDirection={sortDirection}
            onChangeSort={onChangeSort}
          />
        )
      ) : null}
    </Fragment>
  );
};

export default AlgorithmBookmarksPanel;

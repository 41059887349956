export const GENERAL_ERROR_MESSAGE = "Error occured. Please try later!";
export const SESSION_REQ_INTERVAL = 5000;
export const TRUST_USER_LOCAL_KEY = "trust-ai-user";

export const UserStatus = {
  ACTIVE: "active",
  PENDING: "pending",
  DELETED: "deleted",
  SUSPENDED: "suspended",
};

export const ChipColors = new Map([
  [UserStatus.ACTIVE, { background: "#d1fae5", color: "#065f46" }],
  [UserStatus.PENDING, { background: "#e0f2fe", color: "#075985" }],
  [UserStatus.SUSPENDED, { background: "#fef9c3", color: "#854d0e" }],
  [UserStatus.DELETED, { background: "#ffe4e6", color: "#9f1239" }],
]);

export const GET_PROJECTS_QUERY_KEY = "GET_PROJECTS_QUERY_KEY";
export const GET_USERS_QUERY_KEY = "GET_USERS_QUERY_KEY";
export const GET_SESSIONS_QUERY_KEY = "GET_SESSIONS_QUERY_KEY";
export const GET_GENERATIONS_QUERY_KEY = "GET_GENERATIONS_QUERY_KEY";
export const GET_POPULATIONS_QUERY_KEY = "GET_POPULATIONS_QUERY_KEY";
export const GET_CONFIGS_QUERY_KEY = "GET_CONFIGS_QUERY_KEY";
export const GET_EXPLANATIONS_QUERY_KEY = "GET_EXPLANATIONS_QUERY_KEY";
export const GET_EXPLANATION_QUERY_KEY = "GET_EXPLANATION_QUERY_KEY";
export const GET_COUNTERFATUAL_LOGS_QUERY_KEY =
  "GET_COUNTERFATUAL_LOGS_QUERY_KEY";
export const GET_REDASH_DASHBOARD_QUERY_KEY = "GET_REDASH_DASHBOARD_QUERY_KEY";
export const GET_ALGORITHMS_QUERY_KEY = "GET_ALGORITHMS_QUERY_KEY";
export const GET_CUSTOM_FUNCTIONS_QUERY_KEY = "GET_CUSTOM_FUNCTIONS_QUERY_KEY";
export const GET_ALGORITHM_BASED_POPULATIONS_QUERY_KEY =
  "GET_ALGORITHM_BASED_POPULATIONS_QUERY_KEY";

export const DialogMode = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  VIEW: "VIEW",
  QUICK_TEST_SESSION: "QUICK_TEST_SESSION",
};

export const AlgorithmTypes = {
  PREDICTIVE: "predictive",
  PRESCRIPTIVE: "prescriptive",
};

export const SessionTypes = {
  TRAIN: "train",
  TEST: "test",
};

export const ConfigFieldTypes = {
  INTEGER: "integer",
  NUMBER: "number",
  STRING: "string",
  BOOLEAN: "boolean",
  ARRAY: "array",
};

export const ConfigFieldItemTypes = {
  INTEGER: "integer",
  NUMBER: "number",
  STRING: "string",
};

export const SortDirection = {
  ASC: "asc",
  DESC: "desc",
};

export const SessionStatus = {
  RUNNING: "RUNNING",
  FINISHED: "FINISHED",
  COMPLETED: "COMPLETED",
  QUEUED: "QUEUED",
  KILLED: "KILLED",
  FAILED: "FAILED",
  UNKNOWN: "UNKNOWN",
  CREATED: "CREATED",
  CONFIGURED: "CONFIGURED",
  PENDING: "PENDING",
};

export const FIRST_ITEM_INDEX = 0;

export const ExpressionFormat = {
  PREFIX: "prefix",
  INFIX: "infix",
};

export const SessionTabs = {
  TRAINING: "training",
  FILTERING: "filtering",
  EVALUATION: "evaluation",
  DASHBOARD: "redash-dashboard",
};

export const MultiTreeFilterAndSortType = {
  INDIVIDUAL: "Individual",
  BY_TREE: "By Tree",
};

export const ExplainerTypes = {
  ADDITIVE: "additive",
  EXACT: "exact",
  PERMUTATION: "permutation",
  KERNEL: "kernel",
};

export const ShapleyStatus = {
  RUNNING: "RUNNING",
  FINISHED: "FINISHED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CREATED: "CREATED",
  PENDING: "PENDING",
};

export const TableColors = [
  "#fef2f2A6",
  "#f0f9ffA6",
  "#fdf2f8A6",
  "#f7fee7A6",
  "#fff7edA6",
  "#fdf4ffA6",
  "#f7fee7A6",
  "#fffbebA6",
  "#fdf4ffA6",
  "#f5f3ffA6",
];

export const defaultMultiTreeTableHeaders = new Set([
  "id",
  "expr",
  "type",
]);

export const PaginationLimits = {
  _5: 5,
  _10: 10,
  _20: 20,
};

export const MULTI_TREE_FIRST_PAGE = 1;

export const FilterOperators = {
  MIN: ">=",
  MAX: "<=",
};

export const MultiTreeFilterType = {
  MODEL: "model",
  TREE: "tree",
};

export const defaultPopulationModelKeys = ["generation", "individual"];

export const TreeMetricFilterType = {
  UNIVERSAL: "universal",
  EXISTENSIAL: "existential"
}

export const SessionResultsPlotType =  {
  SCATTER: "scatter",
  BAR: "bar",
}

export const DefaultComparisonSortKeys = {
  GENERATION: 'generation',
  INDIVIDUAL: 'individual'
}

export const DEFAULT_CHART_ITEM_SIZE = 2000;

export const SliderButtonDirection = {
  NEXT: 'NEXT',
  PREV: 'PREV'
}
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Copy, SignOut, UserCircle } from "phosphor-react";
import { useAuth } from "../hooks/use-auth";
import logo from "../assets/TRUSTAI-logo.png";
import {
  Avatar,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import StyledMenu from "./common/StyledMenu";
import useNotifier, { NotificationType } from "src/hooks/use-notify";

function Navbar() {
  const theme = useTheme();
  const { notify } = useNotifier();
  let navigate = useNavigate();
  let auth = useAuth();
  const user = auth?.user;
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(undefined);

  const handleOpenUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  return (
    <div id="navbar">
      <img
        onClick={() => {
          navigate("/projects");
        }}
        id="navbar-logo"
        src={logo}
        alt="TRUSTAI Logo"
        style={{ cursor: "pointer" }}
      />
      <ul id="navbar-items">
        <li
          onClick={() => {
            navigate("/projects");
          }}
        >
          Projects
        </li>
        {user?.isAdmin && (
          <li
            onClick={() => {
              navigate("/users");
            }}
          >
            Users
          </li>
        )}
        <li id="signout-container">
          <IconButton
            onClick={handleOpenUserMenu}
            color="inherit"
            sx={{ padding: '0' }}
          >
            {user?.firstname ? (
              <div style={{ margin: "7px" }}>
                <Avatar
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: theme.palette.info.main,
                    fontWeight: "600",
                    fontSize: "1.125rem",
                    transition: "all ease .3s",
                    "&:hover": {
                      backgroundColor: theme.palette.info.dark,
                    },
                  }}
                >
                  {(user?.firstname[0] || "").toUpperCase()}
                </Avatar>
              </div>
            ) : (
              <UserCircle />
            )}
          </IconButton>
        </li>
      </ul>

      <StyledMenu
        id="restart-options-menu"
        anchorEl={userMenuAnchorEl}
        open={Boolean(userMenuAnchorEl)}
        onClose={() => setUserMenuAnchorEl(undefined)}
      >
        <Stack sx={{ padding: ".75rem 1.25rem", gap: "1rem" }}>
          <Stack alignItems={"center"}>
            <Typography>{user?.firstname}</Typography>
            <Typography variant="body2" fontWeight={600}>
              {user?.email}
            </Typography>
          </Stack>

          <Stack alignItems={"center"}>
            <Typography>Api Key:</Typography>
            <Stack direction={"row"} gap={".5rem"} alignItems={"center"}>
              <Typography variant="body2" fontWeight={600}>
                {user?.apiKey}
              </Typography>
              <CopyToClipboard
                text={user?.apiKey}
                onCopy={() => notify(NotificationType.SUCCESS, "Copied!")}
                style={{ cursor: "pointer", color: theme.palette.success.main }}
              >
                <Copy size={20} />
              </CopyToClipboard>
            </Stack>
          </Stack>

          <Button
            color="error"
            sx={{
              padding: ".5rem 0",
              width: "100%",
            }}
            onClick={() => {
              auth.signout();
              navigate("/login");
            }}
          >
            <SignOut size={20} weight="bold" color={theme.palette.error.main} />
            <Typography color={theme.palette.error.main} sx={{ ml: ".5rem" }}>
              Logout
            </Typography>
          </Button>
        </Stack>
      </StyledMenu>
    </div>
  );
}

export default Navbar;
